import {
  ClipboardDocumentListIcon,
  Cog8ToothIcon,
} from '@heroicons/vue/24/outline';

export const NavigationConfig = Object.freeze({
  main: [
    // { name: 'Dashboard', href: '/', icon: Squares2X2Icon },
    {
      name: 'Prescriptions',
      href: '/prescriptions',
      icon: ClipboardDocumentListIcon,
    },
    { name: 'Settings', href: '/settings', icon: Cog8ToothIcon },
  ],
  tools: [
    { name: 'Reporting', href: '/reporting', initial: 'RE' },
  ],
});
